import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { FileText, Briefcase, GraduationCap, Code, Database, Terminal } from 'lucide-react';
import Resume from './Resume';
const ResumePage = () => {
  const resumeRef = useRef(null);

  const workExperience = [
    {
      title: "Director, Website Live Pty Ltd",
      period: "2022-2024",
      description: "My small website design agency: www.website.live"
    },
    {
      title: "Website Developer, Freelance",
      period: "2019-2021",
      description: ""
    },
    {
      title: "PHP Programmer, BusinessBasics",
      period: "2016-2019",
      description: "Created an audit management system for QMS. It enables the ability to manage their clients, certifications, and audits. It is written in PHP and EXTJS."
    },
    {
      title: "IT Rover, University of Newcastle",
      period: "2010-2016",
      description: "The position at the University entails first level computer support for students experiencing computing difficulties. Including connecting their laptop to the Wi-Fi, resolving printing issues, helping with application programs, answering general enquiries, and resetting passwords. The job also has the responsibility of monitoring the 24 hour facility after closing."
    },
    {
      title: "PHP Programmer, Webstuff.biz",
      period: "2012-2014",
      description: "The position entails creating custom coding solutions to satisfy the needs of clients. I've participated in the creation of many websites from the ground up - built registration & login systems, custom content management systems, and administration systems to manage their client data."
    },
    {
      title: "PHP Programmer, WECSGroup",
      period: "2007-2008",
      description: "The role involved developing a web-based software system used for OHS & quality auditing. It was regularly used by Tyco Electronics and a few other corporations to ensure their compliance to the audits. The product was later branded as \"Tricert Assist\" and redeveloped as my web diploma project."
    }
  ];

  const education = [
    {
      degree: "Master of Information Technology (Professional) with Distinction",
      institution: "University of Newcastle",
      period: "2014-2015"
    },
    {
      degree: "Master of Information Technology with Distinction",
      institution: "University of Newcastle",
      period: "2012-2013"
    },
    {
      degree: "Bachelor of Information Technology with Distinction",
      institution: "University of Newcastle",
      period: "2010-2011",
      details: "Software Development and Applications"
    },
    {
      degree: "Diploma of Information Technology",
      institution: "Hunter Institute of TAFE",
      period: "2006-2009",
      details: "Software Development, Website Development, Network Engineering"
    }
  ];

  const skills = {
    programming: ["JAVA", "C++", "C#", "Objective-C", "Python", "Docker"],
    webDevelopment: ["React", "TypeScript", "HTML5", "CSS3", "Javascript", "jQuery", "EXT-JS", "PHP", "ASP.NET", "JAVA Server Pages"],
    databaseManagement: ["MySQL", "MSSQL"]
  };

  const handlePrint = useReactToPrint({
    content: () => resumeRef.current,
    documentTitle: 'Lance_Baker_Resume',
    removeAfterPrint: true,
  });

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Resume</h1>
        <button 
          onClick={handlePrint}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 flex items-center"
        >
          <FileText className="mr-2" /> Download PDF
        </button>
      </div>

      {/* Hidden div for PDF generation */}
      <div style={{ display: 'none' }}>
        <div ref={resumeRef}>
          <Resume />
        </div>
      </div>

      {/* Visible content */}
      <section>
        <h2 className="text-2xl font-bold mb-4 flex items-center">
          <Briefcase className="mr-2" /> Work Experience
        </h2>
        {workExperience.map((job, index) => (
          <div key={index} className="mb-4 bg-white bg-opacity-10 rounded-lg p-4">
            <h3 className="text-xl font-semibold">{job.title}</h3>
            <p className="text-blue-300">{job.period}</p>
            <p className="mt-2">{job.description}</p>
          </div>
        ))}
      </section>

      <section>
        <h2 className="text-2xl font-bold mb-4 flex items-center">
          <GraduationCap className="mr-2" /> Education
        </h2>
        {education.map((edu, index) => (
          <div key={index} className="mb-4 bg-white bg-opacity-10 rounded-lg p-4">
            <h3 className="text-xl font-semibold">{edu.degree}</h3>
            <p className="text-blue-300">{edu.institution}</p>
            <p>{edu.period}</p>
            {edu.details && <p className="mt-2">{edu.details}</p>}
          </div>
        ))}
      </section>

      <section>
        <h2 className="text-2xl font-bold mb-4 flex items-center">
          <Code className="mr-2" /> Skills
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div>
            <h3 className="text-xl font-semibold mb-2 flex items-center">
              <Terminal className="mr-2" /> Programming
            </h3>
            <div className="flex flex-wrap">
              {skills.programming.map((skill, index) => (
                <span key={index} className="bg-purple-600 text-white rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">
                  {skill}
                </span>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2 flex items-center">
              <Code className="mr-2" /> Web Development
            </h3>
            <div className="flex flex-wrap">
              {skills.webDevelopment.map((skill, index) => (
                <span key={index} className="bg-blue-600 text-white rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">
                  {skill}
                </span>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2 flex items-center">
              <Database className="mr-2" /> Database Management
            </h3>
            <div className="flex flex-wrap">
              {skills.databaseManagement.map((skill, index) => (
                <span key={index} className="bg-green-600 text-white rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">
                  {skill}
                </span>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResumePage;