// src/HomePage.js

import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Code, Database, Server, Globe } from 'lucide-react';

const skills = [
  {
    name: 'Web Development',
    icon: Code,
    description: 'Building responsive and dynamic websites using modern frameworks.',
  },
  {
    name: 'Database Management',
    icon: Database,
    description: 'Designing and optimizing relational and NoSQL databases.',
  },
  {
    name: 'Server Administration',
    icon: Server,
    description: 'Managing servers and deploying applications efficiently.',
  },
];

const featuredProjects = [
  {
    name: 'ZIPP.org',
    description: 'Decentralized private internet using IPFS',
    url: 'https://zipp.org',
  },
  {
    name: 'Code Reprogrammer',
    description: 'AI-powered code translation tool',
    url: 'https://github.com/izuc/Reprogrammer',
  },
  {
    name: 'j0b.ai',
    description: 'AI-Powered Job Application Assistant',
    url: 'https://github.com/izuc/J0b.ai',
  },
];

const domains = [
  { name: 'zipp.org', url: 'https://zipp.org' },
  { name: 'map.pics', url: 'https://map.pics' },
  { name: 'friend.software', url: 'https://friend.software' },
  { name: 'tattled.com', url: 'https://tattled.com' },
  { name: 'bot.computer', url: 'https://bot.computer' },
  { name: 'upload.computer', url: 'https://upload.computer' },
  { name: 'coffee.dating', url: 'https://coffee.dating' },
  { name: 'pit.software', url: 'https://pit.software' },
  { name: 'mine.management', url: 'https://mine.management' },
  { name: 'client.management', url: 'https://client.management' },
];

const HomePage = () => {
  return (
    <div className="bg-background text-text">
      {/* Skills Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-heading font-bold text-center mb-12">
            Core Competencies
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {skills.map((skill, index) => (
              <div
                key={index}
                className="bg-white border border-gray-200 rounded-lg p-6 text-center hover:shadow-lg transition"
              >
                <skill.icon size={48} className="text-primary mx-auto mb-4" />
                <h3 className="text-2xl font-semibold mb-2">{skill.name}</h3>
                <p className="text-gray-600">{skill.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Featured Projects Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-heading font-bold text-center mb-12">
            Featured Projects
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {featuredProjects.map((project, index) => (
              <div
                key={index}
                className="bg-white border border-gray-200 rounded-lg shadow hover:shadow-lg transition overflow-hidden"
              >
                <div className="p-6">
                  <h3 className="text-2xl font-semibold mb-2 text-primary">
                    {project.name}
                  </h3>
                  <p className="text-gray-700 mb-4">{project.description}</p>
                  <a
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:text-secondary font-medium"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-12">
            <Link
              to="/portfolio"
              className="inline-flex items-center bg-primary text-white py-3 px-6 rounded-full font-semibold hover:bg-secondary transition"
            >
              View All Projects
              <ArrowRight size={24} className="ml-2" />
            </Link>
          </div>
        </div>
      </section>

      {/* Domains Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-heading font-bold text-center mb-12">
            My Domains
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
            {domains.map((domain, index) => (
              <a
                key={index}
                href={domain.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center bg-white border border-gray-200 rounded-lg p-4 hover:shadow-lg transition"
              >
                <Globe size={24} className="text-primary mr-3" />
                <span className="font-medium">{domain.name}</span>
              </a>
            ))}
          </div>
        </div>
      </section>

      {/* About Me Section */}
      <section className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <div className="text-center md:w-3/4 mx-auto">
            <h2 className="text-4xl font-heading font-bold mb-6">About Me</h2>
            <p className="text-lg text-gray-700 mb-6">
              With over a decade of experience in Information Technology, I
              specialize in creating robust and innovative solutions for complex
              problems. My expertise spans web development, software
              engineering, and database management.
            </p>
            <p className="text-lg text-gray-700 mb-8">
              I'm passionate about leveraging cutting-edge technologies to build
              efficient, scalable, and user-friendly applications that make a
              real impact.
            </p>
            <Link
              to="/resume"
              className="inline-flex items-center bg-primary text-white py-3 px-6 rounded-full font-semibold hover:bg-secondary transition"
            >
              View My Resume
              <ArrowRight size={24} className="ml-2" />
            </Link>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 bg-gradient-to-r from-primary to-secondary text-white text-center">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-heading font-bold mb-6">
            Let's Create Something Exceptional
          </h2>
          <p className="text-xl mb-8">
            Have a project in mind? I'd love to hear about it!
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-amber-600 transition"
          >
            Get in Touch
            <ArrowRight size={24} className="ml-2" />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default HomePage;