import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import HomePage from './HomePage';
import ResumePage from './ResumePage';
import ResumePageDownload from './ResumePageDownload';
import PortfolioPage from './PortfolioPage';
import ContactPage from './ContactPage';
import ReferencePage from './ReferencePage';
import PrivacyPolicyPage from './PrivacyPolicyPage';

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/resume" element={<ResumePage />} />
          <Route path="/resume-download" element={<ResumePageDownload />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/references" element={<ReferencePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;