// src/ContactPage.js

import React, { useState } from 'react';
import { Send, MapPin, Phone, Mail, Globe } from 'lucide-react';
import axios from 'axios';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');
    try {
      const response = await axios.post('/api/contact.php', formData);
      if (response.data.error === 0) {
        setStatus(response.data.info[0].message);
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatus('Error: ' + response.data.info[0].message);
      }
    } catch (error) {
      setStatus('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="bg-background text-text py-16">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-heading font-bold mb-12 text-center">Contact Me</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-text">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-text">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-text">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="6"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 focus:border-primary focus:ring-primary"
                ></textarea>
              </div>
              <button
                type="submit"
                className="inline-flex items-center bg-primary text-white py-3 px-6 rounded-md font-medium hover:bg-secondary transition"
              >
                <Send size={20} className="mr-2" /> Send Message
              </button>
            </form>
            {status && <p className="mt-4 text-primary">{status}</p>}
          </div>

          {/* Contact Information */}
          <div>
            <h2 className="text-2xl font-heading font-semibold mb-6">Contact Information</h2>
            <ul className="space-y-4">
              <li className="flex items-center">
                <MapPin size={24} className="text-primary mr-4" />
                <span>Central Coast, NSW 2250</span>
              </li>
              <li className="flex items-center">
                <Phone size={24} className="text-primary mr-4" />
                <span>02 800 620 33</span>
              </li>
              <li className="flex items-center">
                <Globe size={24} className="text-primary mr-4" />
                <a href="https://www.lance.name" className="text-primary hover:underline">
                  www.lance.name
                </a>
              </li>
              <li className="flex items-center">
                <Mail size={24} className="text-primary mr-4" />
                <span>me@lance.name</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;