// src/ResumePage.js

import React from 'react';
import { Link } from 'react-router-dom';
import {
  FileText,
  Briefcase,
  GraduationCap,
  Code,
  Database,
  Terminal,
  ArrowLeft,
} from 'lucide-react';

const ResumePage = () => {
  const pdfUrl = '/Lance_Baker_Resume.pdf'; // Path to your PDF file

  const workExperience = [
    {
      title: 'Director, Website Live Pty Ltd',
      period: '2022 - 2024',
      description:
        'Led website design agency, delivering custom web solutions for diverse clients across various industries.',
    },
    {
      title: 'Website Developer, Freelance',
      period: '2019 - 2021',
      description:
        'Developed bespoke websites and applications for a variety of clients, enhancing their online presence.',
    },
    {
      title: 'PHP Programmer, BusinessBasics',
      period: '2016 - 2019',
      description:
        'Created an audit management system for QMS, enabling management of clients, certifications, and audits using PHP and EXTJS.',
    },
    {
      title: 'PHP Programmer, Webstuff.biz',
      period: '2012 - 2014',
      description:
        'Developed custom coding solutions, including registration systems, content management systems, and client data administration tools.',
    },
    {
      title: 'IT Rover, University of Newcastle',
      period: '2010 - 2016',
      description:
        'Provided first-level IT support for students, resolving technical issues and enhancing the campus computing experience.',
    },
    {
      title: 'PHP Programmer, WECSGroup',
      period: '2007 - 2008',
      description:
        'Developed a web-based software system for OHS & quality auditing, used by corporations to ensure compliance.',
    },
  ];

  const education = [
    {
      degree: 'Master of Information Technology (Professional) with Distinction',
      institution: 'University of Newcastle',
      period: '2014 - 2015',
    },
    {
      degree: 'Master of Information Technology with Distinction',
      institution: 'University of Newcastle',
      period: '2012 - 2013',
    },
    {
      degree: 'Bachelor of Information Technology with Distinction',
      institution: 'University of Newcastle',
      period: '2010 - 2011',
      details: 'Major: Software Development and Applications',
    },
    {
      degree: 'Diploma of Information Technology',
      institution: 'Hunter Institute of TAFE',
      period: '2006 - 2009',
      details: 'Focus: Software Development, Website Development, Network Engineering',
    },
  ];

  const skills = {
    programming: ['Java', 'C++', 'C#', 'Python', 'Docker'],
    webDevelopment: [
      'React',
      'TypeScript',
      'HTML5',
      'CSS3',
      'JavaScript',
      'PHP',
      'ASP.NET',
    ],
    databaseManagement: ['MySQL', 'MSSQL'],
  };

  return (
    <div className="bg-background text-text py-16">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-4xl font-heading font-bold">Resume</h1>
          <a
            href={pdfUrl}
            download
            className="inline-flex items-center bg-accent text-white py-2 px-4 rounded-full font-medium hover:bg-amber-600 transition"
          >
            <FileText className="mr-2" size={20} /> Download PDF
          </a>
        </div>

        {/* Work Experience */}
        <section className="mb-12">
          <h2 className="text-3xl font-heading font-bold mb-6 flex items-center">
            <Briefcase className="mr-3" size={28} /> Work Experience
          </h2>
          <div className="space-y-8">
            {workExperience.map((job, index) => (
              <div
                key={index}
                className="bg-white border border-gray-200 rounded-lg p-6 hover:shadow-lg transition"
              >
                <h3 className="text-2xl font-semibold text-primary mb-1">
                  {job.title}
                </h3>
                <p className="text-gray-600 mb-4">{job.period}</p>
                <p className="text-gray-700">{job.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Education */}
        <section className="mb-12">
          <h2 className="text-3xl font-heading font-bold mb-6 flex items-center">
            <GraduationCap className="mr-3" size={28} /> Education
          </h2>
          <div className="space-y-8">
            {education.map((edu, index) => (
              <div
                key={index}
                className="bg-white border border-gray-200 rounded-lg p-6 hover:shadow-lg transition"
              >
                <h3 className="text-2xl font-semibold text-primary mb-1">
                  {edu.degree}
                </h3>
                <p className="text-gray-600">
                  {edu.institution} | {edu.period}
                </p>
                {edu.details && <p className="text-gray-700 mt-2">{edu.details}</p>}
              </div>
            ))}
          </div>
        </section>

        {/* Skills */}
        <section className="mb-12">
          <h2 className="text-3xl font-heading font-bold mb-6 flex items-center">
            <Code className="mr-3" size={28} /> Skills
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Programming Skills */}
            <div>
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <Terminal className="mr-2 text-primary" size={24} /> Programming
              </h3>
              <div className="flex flex-wrap gap-2">
                {skills.programming.map((skill, index) => (
                  <span
                    key={index}
                    className="bg-primary text-white rounded-full px-3 py-1 text-sm font-medium"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
            {/* Web Development Skills */}
            <div>
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <Code className="mr-2 text-primary" size={24} /> Web Development
              </h3>
              <div className="flex flex-wrap gap-2">
                {skills.webDevelopment.map((skill, index) => (
                  <span
                    key={index}
                    className="bg-secondary text-white rounded-full px-3 py-1 text-sm font-medium"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
            {/* Database Management Skills */}
            <div>
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <Database className="mr-2 text-primary" size={24} /> Database Management
              </h3>
              <div className="flex flex-wrap gap-2">
                {skills.databaseManagement.map((skill, index) => (
                  <span
                    key={index}
                    className="bg-green-600 text-white rounded-full px-3 py-1 text-sm font-medium"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Back to Home Link */}
        <div className="text-center mt-12">
          <Link
            to="/"
            className="inline-flex items-center text-primary hover:text-secondary font-medium"
          >
            <ArrowLeft size={20} className="mr-2" /> Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResumePage;