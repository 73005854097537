// src/PortfolioPage.js

import React from 'react';
import { ExternalLink, Github } from 'lucide-react';

const PortfolioPage = () => {
  const projects = [
    {
      name: 'Code Reprogrammer',
      description: 'Code translation tool from any language to Java',
      image: '/gallery/code.svg',
      liveUrl: 'https://github.com/izuc/Reprogrammer',
      githubUrl: 'https://github.com/izuc/Reprogrammer',
    },
    {
      name: 'PHP Slim API CRUD Generator',
      description: 'Automates CRUD API creation for PHP Slim',
      image: '/gallery/crud.svg',
      liveUrl: 'https://github.com/izuc/crud-php',
      githubUrl: 'https://github.com/izuc/crud-php',
    },
    {
      name: 'XVEN',
      description: 'eXtensible Virtual ENvironment',
      image: '/gallery/xven.svg',
      liveUrl: 'https://github.com/izuc/xven',
      githubUrl: 'https://github.com/izuc/xven',
    },
    {
      name: 'ZIPP.org',
      description: 'Zone Internet Peer Protocol',
      image: '/gallery/zipp.gif',
      liveUrl: 'https://zipp.org',
      githubUrl: 'https://github.com/izuc/zipp.org',
    },
    {
      name: 'j0b.ai',
      description: 'AI-Powered Job Application Assistant',
      image: '/gallery/j0b_ai.svg',
      liveUrl: 'https://github.com/izuc/J0b.ai',
      githubUrl: 'https://github.com/izuc/J0b.ai',
    },
    {
      name: 'Map.Pics',
      description: 'Photo sharing app for Australian campuses',
      image: '/gallery/map_pics.png',
      liveUrl: 'https://map.pics',
      githubUrl: 'https://github.com/izuc/map.pics',
    },
    {
      name: 'Tattled',
      description: 'Location-based photo social network',
      image: '/gallery/tattled.png',
      liveUrl: 'https://tattled.com',
      githubUrl: null,
    },
    {
      name: 'Friend.Software',
      description: 'Find gaming friends',
      image: '/gallery/friend_software.png',
      liveUrl: 'https://friend.software',
      githubUrl: null,
    },
    {
      name: 'Coffee.Dating',
      description: 'Dating website',
      image: '/gallery/coffee_dating.gif',
      liveUrl: 'https://coffee.dating',
      githubUrl: null,
    },
    {
      name: 'Website.Live',
      description: 'AI-powered website creator',
      image: '/gallery/website_live.png',
      liveUrl: 'https://website.live',
      githubUrl: null,
    },
    {
      name: 'Stall.me',
      description: 'Marketplace for code and assets',
      image: '/gallery/stall_me.png',
      liveUrl: 'https://stall.me',
      githubUrl: null,
    },
  ];

  return (
    <div className="bg-background text-text py-16">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-heading font-bold mb-12 text-center">My Projects</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white border border-gray-200 rounded-lg shadow hover:shadow-lg transition overflow-hidden flex flex-col"
            >
              <img src={project.image} alt={project.name} className="w-full h-100 object-cover" />
              <div className="p-6 flex flex-col flex-grow">
                <h3 className="text-2xl font-semibold mb-2 text-primary">{project.name}</h3>
                <p className="text-gray-700 mb-4 flex-grow">{project.description}</p>
                <div className="mt-auto flex space-x-4">
                  <a
                    href={project.liveUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center text-primary hover:text-secondary font-medium"
                  >
                    <ExternalLink size={20} className="mr-1" /> Live Demo
                  </a>
                  {project.githubUrl && (
                    <a
                      href={project.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center text-primary hover:text-secondary font-medium"
                    >
                      <Github size={20} className="mr-1" /> GitHub
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioPage;