import React from 'react';
import { Briefcase, GraduationCap, Code, Database, Globe, Mail, Phone, MapPin, Github, Linkedin, Terminal, Star, MessageSquare } from 'lucide-react';
import './Resume.css';

const Resume = () => {
    const workExperience = [
        {
            title: "Director, Website Live Pty Ltd",
            period: "2022-2024",
            description: "Led website design agency, delivering custom web solutions for diverse clients across various industries."
        },
        {
            title: "PHP Programmer, BusinessBasics",
            period: "2016-2019",
            description: "Engineered comprehensive audit management system using PHP and EXTJS, streamlining client, certification, and audit processes."
        },
        {
            title: "PHP Programmer, Webstuff.biz",
            period: "2012-2014",
            description: "Created bespoke web applications and sites, emphasizing user experience."
        },
        {
            title: "IT Rover, University of Newcastle",
            period: "2010-2016",
            description: "Provided first-level IT support for students and staff, resolving a wide range of technical issues and enhancing campus computing experience."
        }
    ];

    const education = [
        {
            degree: "Master of Information Technology (Professional)",
            institution: "University of Newcastle",
            period: "2014-2015",
            details: "Graduated with Distinction"
        },
        {
            degree: "Master of Information Technology",
            institution: "University of Newcastle",
            period: "2012-2013",
            details: "Graduated with Distinction"
        },
        {
            degree: "Bachelor of Information Technology",
            institution: "University of Newcastle",
            period: "2010-2011",
            details: "Major in Software Development and Applications"
        }
    ];

    const skills = {
        programming: ["JAVA", "C++", "C#", "Python", "Docker"],
        webDevelopment: ["React", "TypeScript", "HTML5", "CSS3", "Javascript", "PHP", "ASP.NET"],
        databaseManagement: ["MySQL", "MSSQL"]
    };

    const references = [
        {
            name: "Stuart Watkins",
            position: "WECSGroup & BusinessBasics",
            comment: "Lance is an incredibly hard working, forward-thinking programmer with the ability to envisage and solve problems proactively."
        },
        {
            name: "Fiona Neville",
            position: "University of Newcastle",
            comment: "Lance's outstanding communication skills and IT knowledge made him a highly valued member of our team."
        },
        {
            name: "Kalel Johnston",
            position: "Webstuff.biz",
            comment: "Lance's output was exemplary, consistently delivering intuitive and in-depth programming solutions."
        }
    ];

    return (
        <div className="resume-container bg-white text-gray-800 p-8 max-w-4xl mx-auto shadow-2xl">
            <header className="mb-6 pb-6 border-b-2 border-gray-200 flex items-center justify-between">
                <div>
                    <h1 className="text-4xl font-bold mb-2 text-blue-800">Lance Edward Baker</h1>
                    <p className="text-xl text-gray-600 font-semibold mb-3">Programmer &amp; Web Developer</p>
                    <div className="grid grid-cols-2 gap-2 text-sm">
                        <p className="flex items-center"><Phone size={14} className="mr-2 text-blue-600" />02 800 620 33</p>
                        <p className="flex items-center"><Mail size={14} className="mr-2 text-blue-600" />me@lance.name</p>
                        <p className="flex items-center"><Globe size={14} className="mr-2 text-blue-600" />www.lance.name</p>
                        <p className="flex items-center"><MapPin size={14} className="mr-2 text-blue-600" />Gosford, NSW</p>
                    </div>
                </div>
                <div className="flex flex-col items-end">
                    <div className="w-32 h-32 bg-gray-300 rounded-full overflow-hidden shadow-lg border-2 border-blue-200 mb-3">
                        <img src="lance.jpg" alt="Lance Edward Baker" className="w-full h-full object-cover" />
                    </div>
                    <div className="flex space-x-3">
                        <a href="https://au.linkedin.com/in/lance88" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                            <Linkedin size={20} />
                        </a>
                        <a href="https://github.com/izuc/" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-black">
                            <Github size={20} />
                        </a>
                    </div>
                </div>
            </header>

            <div className="grid grid-cols-3 gap-6">
                <div className="col-span-2 space-y-6">
                    <section>
                        <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-800 border-b border-blue-300 pb-2">
                            <Briefcase className="mr-2" size={24} /> Work Experience
                        </h2>
                        <div className="space-y-4">
                            {workExperience.map((job, index) => (
                                <div key={index} className="border-l-4 border-blue-500 pl-4">
                                    <h3 className="text-lg font-semibold text-blue-700">{job.title} <span className="text-sm font-normal text-gray-600">| {job.period}</span></h3>
                                    <p className="text-sm text-gray-800 mt-1">{job.description}</p>
                                </div>
                            ))}
                        </div>
                    </section>

                    <section>
                        <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-800 border-b border-blue-300 pb-2">
                            <MessageSquare className="mr-2" size={24} /> References
                        </h2>
                        <div className="grid grid-cols-3 gap-4">
                            {references.map((ref, index) => (
                                <div key={index} className="border-l-4 border-yellow-500 pl-4">
                                    <h3 className="text-md font-semibold text-blue-700">{ref.name}</h3>
                                    <p className="text-sm text-gray-600">{ref.position}</p>
                                    <p className="text-sm text-gray-800 italic mt-1">"{ref.comment}"</p>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>

                <div className="space-y-6">
                    <section>
                        <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-800 border-b border-blue-300 pb-2">
                            <GraduationCap className="mr-2" size={24} /> Education
                        </h2>
                        <div className="space-y-4">
                            {education.map((edu, index) => (
                                <div key={index} className="border-l-4 border-green-500 pl-4">
                                    <h3 className="text-md font-semibold text-blue-700">{edu.degree}</h3>
                                    <p className="text-sm text-gray-600">{edu.institution}, {edu.period}</p>
                                    <p className="text-sm text-gray-800 mt-1">{edu.details}</p>
                                </div>
                            ))}
                        </div>
                    </section>

                    <section>
                        <h2 className="text-2xl font-bold mb-4 flex items-center text-blue-800 border-b border-blue-300 pb-2">
                            <Code className="mr-2" size={24} /> Skills
                        </h2>
                        <div className="space-y-4">
                            {Object.entries(skills).map(([category, skillList]) => (
                                <div key={category}>
                                    <h3 className="text-lg font-semibold mb-2 text-blue-700 capitalize">{category}</h3>
                                    <div className="flex flex-wrap gap-2">
                                        {skillList.map((skill, index) => (
                                            <span key={index} className="bg-blue-100 text-blue-800 rounded-full px-3 py-1 text-sm font-semibold">
                                                {skill}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Resume;