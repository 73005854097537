// src/Layout.js

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Menu,
  X,
  Linkedin,
  Github,
  Twitter,
  Home,
  FileText,
  Monitor,
  Mail,
  FileCheck,
} from 'lucide-react';

const navItems = [
  { name: 'Home', icon: Home, path: '/' },
  { name: 'Resume', icon: FileText, path: '/resume' },
  { name: 'Portfolio', icon: Monitor, path: '/portfolio' },
  { name: 'References', icon: FileCheck, path: '/references' },
  { name: 'Contact', icon: Mail, path: '/contact' },
];

const socialLinks = [
  {
    icon: Linkedin,
    url: 'https://au.linkedin.com/in/lance88',
    color: 'hover:text-[#0077B5]', // LinkedIn Blue
  },
  {
    icon: Github,
    url: 'https://github.com/izuc/',
    color: 'hover:text-[#333]', // GitHub Gray
  },
  {
    icon: Twitter,
    url: 'https://twitter.com/LanceBlah',
    color: 'hover:text-[#1DA1F2]', // Twitter Blue
  },
];

const Layout = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  return (
    <div className="min-h-screen flex flex-col bg-background text-text">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50">
        <nav className="bg-white shadow-md">
          <div className="container mx-auto px-4 py-3 flex items-center justify-between">
            {/* Site Logo/Name */}
            <Link to="/" className="text-primary font-bold text-2xl">
              Lance Edward
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-6">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`flex items-center font-medium hover:text-primary transition ${
                    location.pathname === item.path ? 'text-primary' : 'text-text'
                  }`}
                >
                  <item.icon size={20} className="mr-1" />
                  {item.name}
                </Link>
              ))}
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="md:hidden text-text focus:outline-none"
            >
              {mobileMenuOpen ? <X size={28} /> : <Menu size={28} />}
            </button>
          </div>

          {/* Mobile Menu */}
          {mobileMenuOpen && (
            <div className="md:hidden bg-white shadow-md">
              <div className="px-4 pt-2 pb-4 space-y-2">
                {navItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className={`block py-2 px-3 rounded hover:bg-gray-100 transition ${
                      location.pathname === item.path ? 'bg-gray-100' : ''
                    }`}
                  >
                    <div className="flex items-center">
                      <item.icon size={20} className="mr-2 text-primary" />
                      <span className="font-medium text-text">{item.name}</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </nav>
      </header>

      {/* Main Content */}
      <main className="flex-grow pt-20">
        {/* Profile Section */}
        <section className="bg-white py-12">
          <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
            <div className="md:w-1/3 mb-6 md:mb-0">
              <img
                src="lance.jpg"
                alt="Lance Edward Baker"
                className="rounded-full w-48 h-48 mx-auto md:mx-0 shadow-md object-cover"
              />
            </div>
            <div className="md:w-2/3 md:pl-12 text-center md:text-left">
              <h1 className="text-4xl font-heading font-bold mb-2 text-primary">
                Lance Edward Baker
              </h1>
              <p className="text-xl mb-4 text-secondary font-semibold">
                Programmer &amp; Web Developer
              </p>
              <p className="mb-6 text-lg">
                Crafting elegant solutions with code. Passionate about building
                efficient and scalable applications that make an impact.
              </p>
              <div className="flex justify-center md:justify-start space-x-4">
                {socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-text transition ${link.color}`}
                  >
                    <link.icon size={24} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Page Content */}
        <div className="container mx-auto px-4 py-8">{children}</div>
      </main>

      {/* Footer */}
      <footer className="bg-white border-t">
        <div className="container mx-auto px-4 py-6 flex flex-col md:flex-row items-center justify-between">
          <p className="text-sm text-gray-600">
            &copy; {new Date().getFullYear()} Lance Edward. All rights reserved.
          </p>
          <div className="mt-4 md:mt-0">
            <Link
              to="/privacy-policy"
              className="text-gray-600 hover:text-primary font-medium"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;